import React from 'react';

import projectSlide from '../components/slide';


import '../styles/slideStyles.css';
import { linkIcon } from '../pages/home';
import gitlabIcon from '../assets/images/websites/gitlab-icon-rgb.png'

import websiteCard from '../components/websiteCard'
import nnDemo from '../assets/videos/nnDemo.mp4'

const website: linkIcon = {
    title: '',
    link: 'https://gitlab.com/AlexSpr/learningasl',
    imageSrc: gitlabIcon
}

const aslSlide: projectSlide = (props) => {

    return (
        <section key='aslSlide' ref={props.ref} className='carousel-slide aslSlide'>

            <video playsInline autoPlay muted loop id="myVideo" style={{ objectFit: 'cover', position: 'absolute' }} >
                <source src={nnDemo}></source>
                Your browser does not support HTML5 video.
                </video>
            {/* <text>BaseSlide is working</text> */}
            <div>
                <h1 className='aslHeader'>Learning <span>A</span>merican <span>S</span>ign <span>L</span>anguage</h1>
                <span className='aslText' style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    Real-time hand sign recognition with a neural network
                </span>
                {websiteCard(website)}
            </div>
        </section>
    );
}


export default aslSlide;