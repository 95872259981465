import React from 'react';

import projectSlide from '../components/slide';

import '../styles/slideStyles.css';
import {linkIcon} from '../pages/home';
import websiteCard from '../components/websiteCard'


import forestBackground from '../assets/images/projects/pexels-markus-spiske-129105.jpg'
import birds from '../assets/images/projects/Birds-PNG-Image2.png'
import githubIcon from '../assets/images/websites/GitHub-Mark-120px-plus.png'


const website: linkIcon = {
    title: '',
    link: 'https://github.com/AlxSp/songbird',
    imageSrc: githubIcon
}

const SongBirdSlide: projectSlide = (props) => {
    return (
        <section key='songBird' ref={props.ref} className='carousel-slide songBirdSlide' style={{backgroundImage: `url(${forestBackground})`}}>
            <img src={birds} alt='' className='birds' style={{pointerEvents: "none"}}/>
            <h1 className='songBirdTitle'><span>S</span>ong<span>B</span>ird</h1>
            <span>detection, classification, and synthesis of bird sounds </span>
            <span>(work in progress...) </span>
            {websiteCard(website)}
        </section>
    );
}

export default SongBirdSlide;