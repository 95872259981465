import React, { useState } from 'react'

interface indexButtonProps {
    index: number,
    isActive: boolean,
    setSlideFN: (index: number) => void,
}

const IndexButton: React.FC<indexButtonProps> = ({index, isActive, setSlideFN} : indexButtonProps) => {
    let opacity = { opacity: isActive ? 1 : 0 }
    let hoverStyle = isActive ? 'carousel-current-index-donut' : ''
    return <button key={index} className={'carousel-index-donut ' + hoverStyle} onClick={ !isActive ? ()=>setSlideFN(index) : ()=>{}}><div style={opacity} className='carousel-current-index-donut-mark'/></button>
}

function createIndexArray(count : number) {
    let indices : number[] = new Array(count);
    for (let i = 0; i < count; i++){
        indices[i] = i;
    }
    return indices;
}

interface indexProps {
    slideCount: number,
    activeSlideIndex: number,
    setSlideFN: (index: number) => void,
}

const CarouselIndex: React.FC<indexProps> = ({slideCount, activeSlideIndex, setSlideFN} : indexProps) => {
    const [slideIndices] = useState(createIndexArray(slideCount));

    return (
        <section className='carousel-index'>
            <div className='carousel-index-line'/>
            {slideIndices.map((_, index) => IndexButton({index, isActive: index === activeSlideIndex, setSlideFN}))}
            <div className='carousel-index-line'/>
        </section>
    );
}

export default CarouselIndex;