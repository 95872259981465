import React from 'react'
import CSS from 'csstype';

import '../styles/pageStyles.css'
import websiteCard from '../components/websiteCard'

import githubIcon from '../assets/images/websites/GitHub-Mark-120px-plus.png';
import gitlabIcon from '../assets/images/websites/gitlab-icon-rgb.png';
import linkedinIcon from '../assets/images/websites/LI-In-Bug.png';
import googleScholarIcon from '../assets/images/websites/512px-Google_Scholar_logo.png';

import resume from '../assets/documents/WebResume.pdf'

import Logo from '../assets/svg/LogoColor.svg'
import { ReactComponent as BottomWaveSeparator } from '../assets/svg/wave.svg'

export type linkIcon = {
  title: string,
  link: string,
  imageSrc: string
}

interface bottomSeparatorProps {
  color?: string
  transform?: CSS.Properties
}

const bottomSeparatorWave: React.FC<bottomSeparatorProps> = ({ color = '#000000', transform = {} }: bottomSeparatorProps) => {
  return (
    <div>
      <BottomWaveSeparator className='bottomSeparator' style={{ fill: color, ...transform }} />
    </div>
  );
}

const renderLinkIcons: React.FC<linkIcon[]> = (icons: linkIcon[]) => {
  return (
    <div className='container websitesContainer'>
      {icons.map((site, index) => websiteCard(site))}
    </div>
  );
}

const websites: linkIcon[] = [
  {
    title: 'Resume',
    link: resume,
    imageSrc: Logo
  },
  {
    title: 'GitHub',
    link: 'https://github.com/AlxSp',
    imageSrc: githubIcon
  }, {
    title: 'GitLab',
    link: 'https://gitlab.com/AlexSpr',
    imageSrc: gitlabIcon
  }, {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/in/alexander-speicher/',
    imageSrc: linkedinIcon
  }, {
    title: 'Google Scholar',
    link: 'https://scholar.google.com/citations?user=6yGciHoAAAAJ&hl=en',
    imageSrc: googleScholarIcon
  }
]

const Home: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <div className='mainBody homeMainBody'>
        <section className='homePageText'>
          <h1 className='text-center'>Hello there,</h1>
          <span>
            I am Alexander Speicher, a recent CS Masters graduate from
            Hofstra University, and welcome to my website. Here you can check out projects and publications I have been
            & am working on. Most of my work is related to machine learning but I also dabble with graphics and simulations.
          </span>
        </section>
          {bottomSeparatorWave({ color: '#e9e9e9', })}
        <section className='homePageLinkSection'>
          {renderLinkIcons(websites)}
          {bottomSeparatorWave({ color: '#a5a5a5' })}
          <div style={{height: '100%', backgroundColor: '#a5a5a5'}}></div>
        </section>

        <section style={{ backgroundColor: '#a5a5a5' }}>
          {bottomSeparatorWave({ color: '#727272' })}
        </section>

      </div>

      <div className='footer homeFooter'>
        <span className='homeFooterText'>
          This website was designed & developed by me, using React and TypeScript
        </span>
      </div>
    </React.Fragment>
  );
}

export default Home;