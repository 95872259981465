import React, { useRef, useState, createRef } from 'react';

import projectSlide from '../components/slide';
import CarouselArrow from '../components/carouselArrow';
import CarouselIndex from '../components/carouselIndex';

const VerticalCarousel: React.FC<projectSlide[]> = (projectSlides) => {

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const [slideCount] = useState(projectSlides.length);

    const slideRefs = useRef(projectSlides.map(() => createRef<HTMLElement>()));

    const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        let nextSlideIndex = Math.floor(event.currentTarget.scrollTop / (event.currentTarget.scrollHeight / slideCount));

        setActiveSlideIndex(nextSlideIndex)
    }

    const goToNextSlide = () => {
        let nextSlideIndex = activeSlideIndex + 1;
        if (activeSlideIndex >= projectSlides.length - 1) {
            nextSlideIndex = 0;
        }

        (slideRefs.current[nextSlideIndex].current as HTMLElement).scrollIntoView({ behavior: "smooth" })
    }

    const goToPreviousSlide = () => {
        let nextSlideIndex = activeSlideIndex - 1;
        if (activeSlideIndex === 0) {
            nextSlideIndex = projectSlides.length - 1;
        }

        (slideRefs.current[nextSlideIndex].current as HTMLElement).scrollIntoView({ behavior: "smooth" })
    }

    const goToSlideOfIndex = (index: number) => {
        (slideRefs.current[index].current as HTMLElement).scrollIntoView({ behavior: "smooth" })        
    }

    return (
        <div onScroll={handleScroll} className='carousel-container'>
            <div className='carousel-contents vertical-carousel-contents' style={{ height: `${slideCount}00%` }}>
                {projectSlides.map((slide, index) => slide({ ref: slideRefs.current[index] }))}
            </div>
            {CarouselArrow({ name: 'Previous', onClickFN: goToPreviousSlide, direction: 'up', render: activeSlideIndex !== 0})}
            {CarouselArrow({ name: 'Next', onClickFN: goToNextSlide, direction: 'down', render: activeSlideIndex !== slideCount - 1})}
            {CarouselIndex({ slideCount: slideCount, activeSlideIndex: activeSlideIndex, setSlideFN : goToSlideOfIndex})}
        </div>
    );
}

export default VerticalCarousel;