import React, { useState } from 'react'
import CSS from 'csstype';

import arrow_right from '../assets/svg/right-arrow.svg'

function pointingArrowRotation(direction: string): number
{
    if (direction === 'right') {
        return 0;
    }
    else if (direction === 'left') {
        return 180;
    }
    else if (direction === 'up') {
        return 270;
    }
    else if (direction === 'down') {
        return 90;
    }

    return 0;
}

function pointingArrowPosition(direction: string): CSS.Properties 
{
    if (direction === 'right') {
        return {right: '0', top: '50%'};
    }
    else if (direction === 'left') {
        return {left: '0', top: '50%'};
    }
    else if (direction === 'up') {
        return {top: '100px', left: '50%'};
    }
    else if (direction === 'down') {
        return {bottom: '5px',left: '50%'};
    }

    return {right: '0', top: '50%'};
}

interface arrowProps {
    name: string,
    onClickFN: () => void,
    direction?: string,
    render?: boolean
}

const CarouselArrow: React.FC<arrowProps> = ({name, onClickFN, direction='', render=true}:arrowProps) => {
    const [degrees] = useState(pointingArrowRotation(direction));
    const [position] = useState(pointingArrowPosition(direction));

    if (render){
        return (   
            <button className='carousel-arrow '
                style ={position}
                onClick={
                    onClickFN
                }
                id={name}>
                <img src={arrow_right} className="carousel-arrow-style" style={{transform: `rotate(${degrees}deg)`, }} alt={name}/>
            </button>
        );
    }
    return null;

}
export default CarouselArrow;