import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { AppState } from '../App';
import Logo from '../assets/svg/Logo.svg'

const NavTopBar: React.FC<AppState> = ({ myName, pages } : AppState) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar className='topNavBar' expand='md' expanded={expanded}>

      <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
        <img
          alt=""
          src={Logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        {myName}
      </Navbar.Brand>
      <Navbar.Toggle className='border-0' aria-controls='navbar-toggle' onClick={() => setExpanded(expanded ? false : true)} />
      <Navbar.Collapse id='navbar-toggle'>
        <Nav className='ml-auto'>
          {
            Object.values(pages).map((page, key) => <Link key={key} className='nav-link' to={page.link} onClick={() => setExpanded(false)} style={{ color: '#000F' }}>{page.title}</Link>)
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavTopBar;