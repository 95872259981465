import React, { useState } from 'react';

//import Carousel from '../components/carousel';
import VerticalCarousel from '../components/verticalCarousel'

import projectSlide from '../components/slide';
import ASLSlide from '../projectSlides/asl';
import NBodySlide from '../projectSlides/nbody';
import SongBirdSlide from '../projectSlides/songbirdSlide';


const projects : projectSlide[] = [
    ASLSlide,
    NBodySlide,
    SongBirdSlide,
]


const Projects: React.FC<{}> = () => {
    const [projectSlides] = useState(projects);
    return (
        <React.Fragment>
        {VerticalCarousel(projectSlides)}
        </React.Fragment>
    );
}

export default Projects