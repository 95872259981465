import React, { useState } from 'react';

import '../styles/pageStyles.css';

const publications: publication[] = [
  {
    title: 'Visual Learning Curves for American Sign Language (ASL) Alphabet',
    authors: ['Salvador Rojas-Murillo', 'Alyssa Pancho', 'Michael Cariaso', 'Alejandro Mato', 'Alexander Speicher', 'Oren Segal'],
    conference: 'International Journal of Industrial Ergonomics',
    publisher: 'IEEE',
    date: new Date('2020/09/05'),
    links: [

    ],
    id: 2
  },
  {
    title: 'Artificial neural network and accelerator co-design using evolutionary algorithms',
    authors: ['Philip Colangelo', 'Oren Segal', 'Alexander Speicher', 'Martin Margala'],
    conference: '2019 IEEE High Performance Extreme Computing Conference (HPEC)',
    publisher: 'IEEE',
    date: new Date('2019/09/24'),
    links: [
      {
        title: 'IEEE Xplore',
        link: 'https://ieeexplore.ieee.org/abstract/document/8916533'
      }
    ],
    id: 1
  },
  {
    title: 'Decentralized Data Center',
    authors: ['Patrick Rygula', 'Alexander Speicher', 'Jorge Contreras', 'Vividh Talwar'],
    conference: '2017 IEEE MIT Undergraduate Research Technology Conference (URTC)',
    publisher: 'IEEE',
    date: new Date('2017/11/03'),
    links: [
      {
        title: 'IEEE Xplore',
        link: 'https://ieeexplore.ieee.org/abstract/document/8284190'
      }
    ],
    id: 0
  }
]

type linkInfo = {
  title: string,
  link: string
}

type publication = {
  title: string,
  authors: string[],
  conference: string,
  publisher: string,
  date: Date,
  links: linkInfo[],
  id: number
}


const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((groups, currentItem) => {
      const groupKey = getKey(currentItem);
      if (!groups[groupKey]) {
        groups[groupKey] = []
      }
      groups[groupKey].push(currentItem);
      return groups;
    }, 
    {} as Record<K, T[]>
  );


function groupAndSortByYear(publications: publication[]) {
  var groupedPublications: Record<number, publication[]> = groupBy(publications, pub => pub.date.getFullYear());
  for (let key in groupedPublications){
    groupedPublications[key].sort((a, b) => b.date.getFullYear() - a.date.getFullYear())
  }
  return (groupedPublications);
}

function getPublicationYears(groupedPublications: Record<number, publication[]>){
    var publicationYears : number[] = [] 
    for (let key in groupedPublications){
      publicationYears.push(parseInt(key));
    }
    return(publicationYears.sort((a, b) => b - a))
  }

const renderLinkList: React.FC<linkInfo[]> = (linkInfoList) => {
  return(
  <span>{linkInfoList.map((info, index) => <span key={index}>[<a href={info.link}>{info.title}</a>]</span>)}</span> //For interactive lists do use 
  );
}

const renderList: React.FC<string[]> = (list) => {
  return(
    <span>{list.map((element, index) => <span key={index}>{(index ? ', ' : '') + element}</span>)}</span> 
  );
}

const renderPublication: React.FC<publication> = ({title, authors, conference, links, id}: publication) => {
  return(
    <div key={id} className='container publicationContainer'>
      <div className='publicationItem'><b>{title}</b></div>
      <div className='publicationItem'>{renderList(authors)}</div>
      <div className='publicationItem'>{conference}</div>
      <div className='publicationItem'>{renderLinkList(links)}</div>
    </div>
  );
}
 
const renderPublicationYear: React.FC<{ year: number, publications: publication[] }> = ({year, publications}) => {
  return (
    <div key={year}>
      <h5 className='container publicationYearHeader'>{year}</h5>
      {publications.map((publication, index) => renderPublication(publication))}
    </div>
  );
}  

const Publications: React.FC<{}> = () => {

  const [groupedByYearPublications] = useState(groupAndSortByYear(publications));
  const [publicationYears] = useState(getPublicationYears(groupedByYearPublications));

  return (
    <React.Fragment>
      <h1 className='text-center pageHeader'>Publications</h1>
      {publicationYears.map((year) => {return(renderPublicationYear({year: year, publications: groupedByYearPublications[year]}))})}
    </React.Fragment>
  );
}

export default Publications;