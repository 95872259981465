import React from 'react';

import '../styles/websiteCard.css';
import {linkIcon} from '../pages/home';



const websiteCard: React.FC<linkIcon> = ({title, link, imageSrc} : linkIcon) => {
    return(
        <div key={title} className='websiteCard' style={{zIndex:1}}>
            <a href = {link} className='websiteCard'>
                <img src={imageSrc} alt={title} className='cardImage'/>
                <span><b>{title}</b></span>
            </a>
        </div>
    );
}

export default websiteCard;