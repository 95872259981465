import React from 'react';

import projectSlide from '../components/slide';

import '../styles/slideStyles.css';
import {linkIcon} from '../pages/home';
import websiteCard from '../components/websiteCard'

import gitlabIcon from '../assets/images/websites/gitlab-icon-rgb.png'

import NBodyVideo from '../assets/videos/NBody.mp4'

const website: linkIcon = {
    title: '',
    link: 'https://gitlab.com/AlexSpr/n-body',
    imageSrc: gitlabIcon
}


const NBodySlide: projectSlide = (props) => {
    // Call the hook passing in ref and root margin
    // In this case it would only be considered onScreen if more ...
    // ... than 300px of element is visible.
    return (
        <section key='nbodySlide' ref={props.ref} className='carousel-slide nbodySlide'>
            <video playsInline autoPlay muted loop  id="myVideo" style={{objectFit: 'cover', position: 'absolute'}}>
                <source src={NBodyVideo}></source>
                Your browser does not support HTML5 video.
            </video>    
            <div>
            <h1 className='songBirdTitle'>N-Body</h1>
                <span>
                    simulation with OpenCL and OpenGL     
                </span>
                    {websiteCard(website)}
            </div>
        </section>
    );
}

export default NBodySlide;