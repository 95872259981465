import React, { Component } from 'react';
import {BrowserRouter, Switch, Route } from 'react-router-dom';

import NavTopBar from './components/navbar';

import Home from './pages/home';
import Projects from './pages/projects';
import Publications from './pages/publications';
// import CurriculumVitae from './pages/curriculumVitae';

export type page = {
  title: string,
  link: string,
  pageFn: React.FC<{}>
};

export type AppState = {
  myName: string,
  pages: Record<string, page>
}

class App extends Component<{}, AppState> {

  constructor(props: {})
  {
    super(props);
    this.state = {
      myName: 'Alexander Speicher',
      pages: {
        HomePage: {
          title : 'Home',
          link  : '/',
          pageFn: Home

        },
        ProjectsPage: {
          title : 'Projects',
          link  : '/projects',
          pageFn: Projects
        },
        PublicationsPage: {
          title : 'Publications',
          link  : '/publications',
          pageFn: Publications
        },
        // CurriculumVitaePage: {
        //   'title' : 'Curriculum Vitae',
        //   'link'  : '/curriculumvitae'
        // },
    }
    };
  }

  render()
  {
    const pages = this.state.pages;

    return (
      <React.Fragment>
        <BrowserRouter>
          <NavTopBar myName={this.state.myName} pages={this.state.pages}/>
          <Switch>
            {
              Object.values(pages).map((page, index) => <Route key={index} path={page.link} exact component={page.pageFn}/>)
            }
            {/* <Route path={pages.HomePage.link} exact component={Home}/>
            <Route path={pages.ProjectsPage.link} exact component={Projects}/>
            <Route path={pages.PublicationsPage.link} component={Publications}/> */}
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
